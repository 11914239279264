import { useEffect, useState } from 'react';

const IDLE = 'idle';
const LOADING = 'loading';
const READY = 'ready';
const ERROR = 'error';

type StatusType = typeof IDLE | typeof LOADING | typeof READY | typeof ERROR;

export const useScript = (src: string, className?: string) => {
  const [status, setStatus] = useState<StatusType>(src ? LOADING : IDLE);

  useEffect(
    () => {
      /*
       * Allow falsy src value if waiting on other data needed for
       * constructing the script URL passed to this hook.
       */
      if (!src) {
        setStatus(IDLE);
        return undefined;
      }
      /*
       * Fetch existing script element by src
       * It may have been added by another intance of this hook
       */
      let scriptElement: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);
      if (scriptElement) {
        setStatus((scriptElement.getAttribute('data-status') || 'error') as StatusType);
      } else {
        // Create and Mount a Script Element
        scriptElement = document.createElement('script');
        scriptElement.src = src;
        scriptElement.async = true;
        if (className) scriptElement.className = className;

        scriptElement.setAttribute('data-status', 'loading');

        // Add script to document body
        document.body.appendChild(scriptElement);
      }

      // Add event listeners to the found (or unfound)
      const handleSetStatus = (event: Event) => {
        scriptElement?.setAttribute('data-status', event.type === 'load' ? READY : ERROR);
        setStatus(event.type === 'load' ? READY : ERROR);
      };

      scriptElement.addEventListener('load', handleSetStatus);
      scriptElement.addEventListener('error', handleSetStatus);

      return () => {
        // Remove event listeners and the element itself on cleanup
        scriptElement?.removeEventListener('load', handleSetStatus);
        scriptElement?.removeEventListener('error', handleSetStatus);
        scriptElement?.remove();
      };
    },
    [src, className] // Only re-run effect if script src changes
  );

  return status;
};
