import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import {
  Container,
  HeroContainer,
  Section,
  SectionImage,
  SectionVideo,
} from 'components/Container';
import { GatsbyLink, SignupLink } from 'components/Links';
import { ImageCards } from 'components/ImageCard';
import { TrustPilot } from 'components/TrustPilot';
import CtaGraph from 'images/shared-page-assets/ctagraph.inline.svg';

import { featuresCards, supportedCards } from 'page-data/features';

import Security from 'images/features/security.inline.svg';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const Features: FC<LocalizedPageProps> = () => (
  <Layout hideFooterCta>
    <Seo />
    <HeroContainer background="cream" wrapMobile="hard">
      <Section>
        <h1>The best portfolio and dividend tracker</h1>
        <p>
          Automatically track price, performance and dividends from 240,000+ global stocks, crypto,
          ETFs and funds. Add cash accounts and property to get the full picture of your portfolio –
          all in one place.
        </p>
        <br />
        <SignupLink asButton showArrowIcon>
          Sign up for free
        </SignupLink>
      </Section>
      <SectionImage
        image={useGatsbyImage({
          name: 'features/portfolio-hero',
          alt: "User Interface of Sharesight's portfolio and dividend tracker with property, investment, bitcoin and currency icons",
          loading: 'eager',
        })}
        boxShadow={false}
        borderRadius={false}
      />
    </HeroContainer>
    <Container>
      <ImageCards cards={featuresCards} />
    </Container>
    <Container>
      <Section>
        <h2>See the best portfolio tracker in action</h2>
      </Section>

      <SectionVideo videoUrl="https://www.youtube.com/watch?v=IIgxxIfHOis&ab_channel=Sharesight" />
    </Container>
    <Container>
      <SectionImage
        image={useGatsbyImage({
          name: 'features/diversification',
          alt: "Screenshot of Sharesight's Diversity Report on a laptop and mobile device",
        })}
        boxShadow={false}
        borderRadius={false}
      />
      <Section>
        <h2>See your diversification</h2>
        <p>
          Get deeper insights into your portfolio by exploring your asset allocation and
          diversification with the Diversity Report.
        </p>
        <br />
        <GatsbyLink asButton appearance="secondary" to="/pricing/">
          View pricing plans
        </GatsbyLink>
      </Section>
    </Container>
    <Container>
      <ImageCards cards={supportedCards} />
    </Container>
    <Container background="navyBlue">
      <Section>
        <h2>Your security matters to us</h2>
        <p>
          Our security standards follow best practice. We are regularly independently audited,
          encrypt everything sent between you and our servers and offer 2-factor authentication.
        </p>
      </Section>
      <SectionImage image={<Security />} boxShadow={false} borderRadius={false} />
    </Container>
    <TrustPilot />
    <Container background="orangeHighlight">
      <Section>
        <h2>Start tracking your performance for free</h2>
        <p>Track up to 10 holdings. No credit card needed.</p>
        <br />
        <SignupLink asButton showArrowIcon>
          Sign up for free
        </SignupLink>
      </Section>
      <SectionImage image={<CtaGraph />} boxShadow={false} borderRadius={false} />
    </Container>
  </Layout>
);

export default Features;
