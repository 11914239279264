import PerformanceCardImage from 'images/features/features-cards/know-your-performance.inline.svg';
import AutomateCardImage from 'images/features/features-cards/automate-dividends.inline.svg';
import TaxReportCardImage from 'images/features/features-cards/tax-reports.inline.svg';
import BrokerCardImage from 'images/features/features-cards/brokers.inline.svg';
import MarketCardImage from 'images/features/features-cards/markets.inline.svg';
import SoftwareCardImage from 'images/features/features-cards/software.inline.svg';
import type { ImageCardProps } from 'components/ImageCard';

export const featuresCards: ImageCardProps[] = [
  {
    title: 'Know your real performance',
    content: (
      <>
        Compare performance across all of your brokers and assets with detailed reporting, charts,
        price and currency updates.
      </>
    ),
    background: 'primaryHighlight',
    image: <PerformanceCardImage />,
    to: '/investment-portfolio-performance/',
    showButton: true,
    buttonText: 'See your performance',
  },
  {
    title: 'Automate your dividend tracking',
    content: (
      <>
        Watch as corporate actions such as dividends, DRPs and share splits are automatically
        updated in your portfolio
      </>
    ),
    background: 'yellowHighlight',
    image: <AutomateCardImage />,
    to: '/dividend-tracker/',
    showButton: true,
    buttonText: 'Track dividends',
  },
  {
    title: 'Instantly share your tax reports',
    content: (
      <>
        Share your portfolio and tax reports with your accountant and save time, money and hassle
        during the tax season.
      </>
    ),
    background: 'orangeHighlight',
    image: <TaxReportCardImage />,
    to: '/investment-portfolio-tax/',
    showButton: true,
    buttonText: 'Share tax reports',
  },
];

export const supportedCards: ImageCardProps[] = [
  {
    title: 'Brokers',
    content: <>Automatically import trades from 200+ global brokers.</>,
    background: 'primaryHighlight',
    image: <BrokerCardImage />,
    to: '/partners/',
    localize: true,
    showButton: true,
  },
  {
    title: 'Markets',
    content: <>Track stocks, ETFs and funds in 40+ markets worldwide.</>,
    background: 'yellowHighlight',
    image: <MarketCardImage />,
    to: '/supported-stock-exchanges-managed-funds-mutual-funds/',
    localize: false,
    showButton: true,
  },
  {
    title: 'Software',
    content: <>Connect to any of our leading software partners.</>,
    background: 'orangeHighlight',
    image: <SoftwareCardImage />,
    to: '/partners/',
    showButton: true,
    localize: true,
  },
];
